<template>
  <div class="_page-content">
    <ax-table
      ref="table"
      :columns="columns"
      :dataSourceApi="api.list"
      :action-width="120"
      :searchActions="searchActions"
      :searchForm="searchForm"
      :scroll="{ y: '55vh', x: '80vw' }"
      @action-column-click="actionColumnClick"
      :sqlParams="sqlParams"
      @pop-confirm="popConfirm"
      @add="handleAdd"
    >
      <template #roleType="{record}">
        {{record.roleType === '0' ? '内部' : '协作单位'}}
      </template>
    </ax-table>
    <a-drawer
      :title="title"
      :width="650"
      :closable="true"
      :maskClosable="false"
      :visible="visible"
      :destroy-on-close="true"
      @close="close"
    >
      <ax-form
        ref="formBox"
        :formBuilder="formBuilder"
      ></ax-form>
      <div class="drawer-bootom-button">
        <a-popconfirm
          title="确定放弃编辑？"
          @confirm="handleCancel"
          okText="确定"
          cancelText="取消"
        >
          <a-button style="margin-right: 0.8rem">取消</a-button>
        </a-popconfirm>
        <a-button
          @click="handleSubmit"
          type="primary"
          :loading="confirmLoading"
        >提交</a-button>
      </div>
    </a-drawer>
    <!-- 表单区域 -->
    <user-role-modal ref="modalUserRole"></user-role-modal>
  </div>
</template>

<script>
import api from './api'
import UserRoleModal from './UserRoleModal'
import { initGridFormData, isEmpty } from '@/common/tools'

const searchForm = [
  // {
  //   label: '角色编码',
  //   type: 'input',
  //   model: 'roleCode',
  //   options: {},
  //   formItem: {},
  //   col: { span: 6 }
  // },
  {
    label: '角色名称',
    type: 'input',
    model: 'roleName',
    options: { options: status },
    formItem: {},
    col: { span: 6 }
  },
  {
    label: '角色描述',
    type: 'input',
    model: 'description',
    options: {},
    formItem: {},
    col: { span: 6 }
  }
]
export default {
  components: {
    UserRoleModal
  },
  name: 'role',
  data () {
    return {
      api,
      report: {},
      title:'添加角色',
      visible: false,
      confirmLoading: false,
      shortcutForm: [], // 快捷查询form
      searchForm: initGridFormData(searchForm, {
        layout: 'horizontal',
        labelWidth: 90
      }),
      object: {},
      sqlParams: { like: ['roleCode', 'roleName', 'description'] },
      columns: this.$ax.tools.initColumn(
        [
          // {
          //   title: '角色编码',
          //   dataIndex: 'roleCode',
          //   ellipsis: true,
          //   key: 1,
          //   width: 140
          // },
          {
            title: '角色名称',
            dataIndex: 'roleName',
            ellipsis: true,
            key: 2,
            width: 180
          },
          {
            title: '角色描述',
            dataIndex: 'description',
            ellipsis: true,
            key: 3,
            width: 300
          },
          // {
          //   title: '角色类型',
          //   dataIndex: 'roleType',
          //   key: 4,
          //   width: 100
          // }
        ],
        true,
        {
          width: 120,
          actions: [
            {
              props: (record) => {
                return {
                  text: '编辑',
                  name: 'edit',
                  type: '#3853db',
                  link: true,
                  loading: record.id === 0
                }
              }
            },
            {
              props: (record) => {
                return {
                  text: '配置菜单',
                  name: 'authorize',
                  type: '#3853db',
                  link: true,
                  loading: record.id === 0
                }
              }
            },
            {
              props: (record) => {
                return {
                  text: '删除',
                  name: 'del',
                  type: '#3853db',
                  link: true,
                  loading: record.id === 0,
                  popDisabled: false,
                  title: '确定删除该角色？'
                }
              }
            }
          ]
        }
      ),
      tableLoading: false, // 表格loading效果，需要用变量定义
      queryParams: {}, // 查询条件
      queryType: {
        // 查询类型
        like: ['', '']
      },
      searchActions: [
        { name: 'add', text: '添加角色' },
        { name: 'search', text: '查询' },
        { name: 'reset', text: '重置  ' }
      ],
      url: {
        deleteBatch: '/sys/role/deleteBatch'
      },
      formBuilder: initGridFormData([
        {
          label: 'id',
          type: 'input',
          model: 'id',
          options: {
            placeholder: '请输入角色编码',
            allowClear: true,
            hide: true
          },
          rules: [{ required: true, message: '请输入角色编码' }],
          formItem: { colon: false },
          col: { span: 24 }
        },
        // {
        //   label: '角色编码',
        //   type: 'input',
        //   model: 'roleCode',
        //   options: {
        //     placeholder: '请输入角色编码',
        //     allowClear: true
        //   },
        //   rules: [{ required: true, message: '请输入角色编码' }],
        //   formItem: { colon: false },
        //   col: { span: 24 }
        // },
        {
          label: '角色名称',
          type: 'input',
          model: 'roleName',
          options: {
            placeholder: '请输入角色名称',
            allowClear: true
          },
          rules: [{ required: true, message: '请输入角色名称' }],
          formItem: { colon: false },
          col: { span: 24 }
        },
        {
          label: '角色描述',
          type: 'input',
          model: 'description',
          options: {
            placeholder: '请输入角色描述',
            allowClear: true
          },
          rules: [{ required: true, message: '请输入角色描述' }],
          formItem: { colon: false },
          col: { span: 24 }
        },
        // {
        //   label: '角色类型',
        //   type: 'select',
        //   model: 'roleType',
        //   options: {
        //     placeholder: '请选择角色类型',
        //     allowClear: true,
        //     options: [
        //       { label: '内部', value: '0' },
        //       { label: '协作单位', value: '1' }
        //     ]
        //   },
        //   rules: [{ required: true, message: '请选择角色类型' }],
        //   formItem: { colon: false },
        //   col: { span: 24 }
        // }
      ])
    }
  },
  methods: {
    getList () {
      this.$refs.table.getDataSource()
    },
    actionColumnClick (args) {
      const {
        btn: { name },
        record
      } = args
      switch (name) {
        case 'authorize':
          this.$refs.modalUserRole.show(record.id)
          break
        case 'edit':
          this.visible = true
          this.object = record
          this.title = '编辑角色'
          this.$nextTick(() => {
            this.$refs.formBox.setFieldsValue(record)
          })
          break
      }
    },
    handleAdd () {
      this.visible = true
      this.title = '添加角色'
    },
    close () {
      this.visible = false
    },
    popConfirm ({ record, btn: { name } }) {
      if (name === 'del') {
        api.del(record.id).then(() => {
          this.$message.success('删除成功')
          this.getList()
        })
      }
    },
    handleSubmit () {
      this.$refs.formBox.form.validateFields((err, values) => {
        if (!err) {
          this.confirmLoading = true
          if (!isEmpty(this.object)) {
            values.id = this.object.id
          }
          api.add(values).then(() => {
            this.confirmLoading = false
            this.visible = false
            this.$refs.formBox.form.resetFields()
            this.getList()
          })
        }
      })
    },
    handleCancel () {
      this.$refs.formBox.form.resetFields()
      this.close()
    },
    deleteBatch (id) {
      api.de(id).then(() => {
        this.$message({
          type: 'success',
          message: '删除成功!'
        })
        this.getList()
      })
    }
  }
}
</script>
<style>
.drawer-bootom-button {
  position: absolute;
  bottom: -8px;
  width: 100%;
  border-top: 1px solid #e8e8e8;
  padding: 10px 16px;
  text-align: right;
  left: 0;
  background: #fff;
  border-radius: 0 0 2px 2px;
}
</style>
